<script setup lang="ts">
import Timer from "./helpers/timer";
import { definePosition } from "./helpers/position";
import { removeElement } from "./helpers/element";

export interface BaseToastProps {
  message: string;
  type: "success" | "danger";
  position?:
  | "top-right"
  | "top"
  | "top-left"
  | "bottom-right"
  | "bottom"
  | "bottom-left";
  duration?: false | number;
  dismissible?: boolean;
  pauseOnHover?: boolean;
  onClose?: Function;
  onClick?: Function;
}

const props = withDefaults(defineProps<BaseToastProps>(), {
  duration: 4000,
  position: "top",
  dismissible: true,
  pauseOnHover: true,
});

const rootEl = ref(null);
const timer = ref<Timer | null>(null);
const isActive = ref(false);
const parentTop = ref<HTMLElement | null>(null);
const parentBottom = ref<HTMLElement | null>(null);
const transition = computed(() => {
  return definePosition(
    props.position,
    { enter: "fadeInDown", leave: "fadeOut" },
    { enter: "fadeInUp", leave: "fadeOut" },
  );
});
const correctParent = computed(() =>
  definePosition(props.position, parentTop.value, parentBottom.value),
);

function createParents() {
  parentTop.value = document.querySelector(".BaseToast-container--top");
  parentBottom.value = document.querySelector(".BaseToast-container--bottom");
  if (parentTop.value && parentBottom.value)
    return;

  if (!parentTop.value) {
    parentTop.value = document.createElement("div");
    parentTop.value.className = "BaseToast-container BaseToast-container--top";
  }
  if (!parentBottom.value) {
    parentBottom.value = document.createElement("div");
    parentBottom.value.className
      = "BaseToast-container BaseToast-container--bottom";
  }
}

function setupContainer() {
  const container = document.body;
  if (parentTop.value && parentBottom.value) {
    container.appendChild(parentTop.value);
    container.appendChild(parentBottom.value);
  } else {
    console.error("Failed to setup toast containers!");
  }
}

function showNotice() {
  correctParent.value.insertAdjacentElement("afterbegin", rootEl.value);
  isActive.value = true;
  timer.value
    = props.duration !== false ? new Timer(close, props.duration) : null;
}
function toggleTimer(newVal: boolean) {
  if (timer.value && props.pauseOnHover)
    newVal ? timer.value.pause() : timer.value.resume();
}
function stopTimer() {
  timer.value && timer.value.stop();
}
function close() {
  stopTimer();
  isActive.value = false;
  setTimeout(() => {
    props.onClose && props.onClose();
    rootEl.value && removeElement(rootEl.value);
  }, 150);
}

function onClick() {
  props.onClick && props.onClick();

  if (props.dismissible)
    close();
}

onBeforeMount(() => {
  createParents();
  setupContainer();
});
onMounted(() => showNotice());
</script>

<template>
  <transition
    :enter-active-class="transition.enter"
    :leave-active-class="transition.leave"
  >
    <div
      v-show="isActive"
      ref="rootEl"
      class="BaseToast"
      :class="[`BaseToast--${props.type}`, `BaseToast--${props.position}`]"
      role="alert"
      @mouseover="toggleTimer(true)"
      @mouseleave="toggleTimer(false)"
      @click="onClick"
    >
      <BaseAlert
        :type="props.type"
        :text="props.message"
        :dismissible="props.dismissible"
      />
    </div>
  </transition>
</template>

<style lang="scss">
@import "./BaseToast.scss";
</style>
